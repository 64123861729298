export const environment = {
  production: false,
  apiUrl: 'https://apirendafin.pulpitests.com/api/v4/',
  incodeUrl:'https://saas-api.incodesmile.com/omni/',
  incodeApiKey: 'd64c816f6605a117021f2b1258570c8de0bbb8cc',
  incodeLoanConfigurationID: '64835c1467f7672a92b7bb4f',
  weetrusthApiKey: '2d446548b7687c76bb14f9cfbf447d76819f348d',
  weetrusthIdUsuario: 'PAi8rzsNTZZKibJEqdqlBZszJyQ2',
  weetrustUrl: 'https://api.weetrust.mx/'
}
