<ng-container *ngIf="data">
  <p-toast></p-toast>
  <div class="grid">
    <div class="col-12">
      <div class="main-title">
        Datos del crédito
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto Solicitado:</h3>
      <p>{{data.amount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Tasa de interes anual:</h3>
      <p>{{data.rate | percent}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Comisión por apertura:</h3>
      <p>{{data.creationFee | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Número de letras:</h3>
      <p>{{data.installmentCount}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto de letra:</h3>
      <p>{{data.installmentAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Estatus:</h3>
      <p>
        <p-tag [value]="data.status | selectEnum:$_statusLoan" [severity]="data.status | selectEnum:$_statusLoanColor"></p-tag>
      </p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Asignaciones:</h3>
      <ng-container *ngFor="let assignment of assignments">
        <p>{{assignment?.adminId?.names?.firstName}} {{assignment?.adminId?.names?.lastName}} | {{assignment?.adminId?.emailAddress ?? ''}}</p>
      </ng-container>
    </div>
    <div class="col-12">
      <div class="main-title">
        Montos cobrados antes de dispersion
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de comision por apertura:</h3>
      <p>{{data.creationFee | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de accesorios sin financiar:</h3>
      <p>{{data.accessoryCreation | currency}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
        Montos por cobrar
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de capital:</h3>
      <p>{{data.outstandingPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de intéres:</h3>
      <p>{{data.outstandingInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de capital de accesorios:</h3>
      <p>{{data.outstandingAccessoryPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar de intéres de accesorios:</h3>
      <p>{{data.outstandingAccessoryInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto por cobrar total:</h3>
      <p>{{(data.outstandingInterestAmount + data.outstandingPrincipalAmount + data.outstandingAccessoryInterestAmount + data.outstandingAccessoryPrincipalAmount) | currency}}</p>
    </div>
    <div class="col-12">
      <div class="main-title">
       Montos cobrados
      </div>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de capital:</h3>
      <p>{{data.collectedPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de intéres:</h3>
      <p>{{data.collectedInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de capital de accesorios:</h3>
      <p>{{data.collectedAccessoryPrincipalAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado de intéres de accesorios:</h3>
      <p>{{data.collectedAccessoryInterestAmount | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Monto cobrado total:</h3>
      <p>{{(data.collectedInterestAmount + data.collectedPrincipalAmount + data.collectedAccessoryInterestAmount + data.collectedAccessoryPrincipalAmount) | currency}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de solicitud:</h3>
      <p>{{data.creationDate | isoDateEnum}}</p>
    </div>
    <div class="col-4">
      <h3 class="mb-2">Fecha de dispersión:</h3>
      <p>{{data.date | isoDateEnum}}</p>
    </div>
    <div class="col-12">
      <p-tabView styleClass="tabview-custom">
        <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_AMORTIZATION_TABLE');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-receipt"></i>
              <span class="ml-2">Tabla de amortización</span>
            </ng-template>
           <div class="grid">
             <div class="col-12">
               <div class="main-title">
                 Tabla de amortización
               </div>
             </div>
             <div class="col-12">
               <p-table #dtLoanResume [value]="installments" [tableStyle]="{ 'min-width': '50rem' }">
                 <ng-template pTemplate="caption">
                   <div class="flex flex-row justify-content-between">
                     <ng-container *ngIf="authService.userHasAuthorization('LOAN','EXPORT_AMORTIZATION_TABLE');">
                       <p-splitButton label="Acciones" [model]="items" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
                     </ng-container>
                     <div>
            <span class="p-input-icon-left mr-1">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtLoanResume.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
            </span>
                     </div>
                   </div>
                 </ng-template>
                 <ng-template pTemplate="header">
                   <tr>
                     <th style="min-width: 10rem">Número de letra</th>
                     <th style="min-width: 10rem">Monto de capital</th>
                     <th style="min-width: 12rem">Monto del interés</th>
                     <th style="min-width: 12rem">Monto del capital de accesorios</th>
                     <th style="min-width: 12rem">Monto de intereses de accesorios</th>
                     <th style="min-width: 10rem">Monto total</th>
                     <th style="min-width: 15rem">Fecha de exigibilidad</th>
                     <th style="min-width: 10rem">Monto cobrado</th>
                     <th style="min-width: 15rem">Estatus de pagado</th>
                   </tr>
                 </ng-template>
                 <ng-template pTemplate="body" let-installment>
                   <tr>
                     <td>{{ +installment.index + 1}}</td>
                     <td>{{ installment.principalAmount | currency }}</td>
                     <td>{{ installment.revenueAmount | currency }}</td>
                     <td>{{ installment.accessoryPrincipalAmount ?? 0 | currency }}</td>
                     <td>{{ installment.accessoryRevenueAmount ?? 0 | currency }}</td>
                     <td>{{ (installment.revenueAmount + installment.principalAmount + (installment.accessoryPrincipalAmount ?? 0) + (installment.accessoryRevenueAmount ?? 0)) | currency }}</td>
                     <td>{{ installment.dueDate | isoDateEnum }}</td>
                     <td>{{ installment.collectedAmount | currency }}</td>
                     <td>{{( installment.collectedAmount + 0.1) >= (installment.principalAmount + installment.revenueAmount) ? 'Pagado' : 'Pendiente' }}</td>
                   </tr>
                 </ng-template>
               </p-table>
             </div>
           </div>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_APPLICATIONS_TABLE');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-file-invoice"></i>
              <span class="ml-2">Tabla de aplicaciones / pagos</span>
            </ng-template>
            <div class="grid">
              <div class="col-12">
                <div class="main-title">
                  Tabla de aplicaciones / pagos
                </div>
              </div>
              <div class="col-12">
                <p-table #dtPayments [value]="payments" [tableStyle]="{ 'min-width': '50rem' }">
                  <ng-template pTemplate="caption">
                    <div class="flex flex-row justify-content-between">
                      <ng-container *ngIf="authService.userHasAuthorization('LOAN','EXPORT_APPLICATIONS_TABLE');">
                        <p-splitButton label="Acciones" [model]="itemsPayments" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
                      </ng-container>
                      <div>
            <span class="p-input-icon-left mr-1">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtPayments.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
            </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th  style="min-width: 10rem">Número de letra</th>
                      <th  style="min-width: 15rem">Fecha de exigibilidad</th>
                      <th  style="min-width: 10rem">Monto de la aplicación</th>
                      <th  style="min-width: 15rem">Fecha de la aplicación</th>
                      <th  style="min-width: 15rem">Responsable de aplicación</th>
                      <th  style="min-width: 10rem">CLABE receptora</th>
                      <th  style="min-width: 10rem">Clave de rastreo</th>
                      <th  style="min-width: 10rem">Monto original del pago</th>
                      <th  style="min-width: 10rem">Fecha del pago</th>
                      <th  style="min-width: 10rem">Estatus</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-payment>
                    <tr>
                      <td>{{+payment.installment.index + 1}}</td>
                      <td>{{payment.installment.dueDate | isoDateEnum}}</td>
                      <td>{{payment.application.amountApplied | currency}}</td>
                      <td>{{payment.application.dateApplication | date: 'dd-MM-YYYY hh:mm:ss'}}</td>
                      <td>{{payment.application.admin}}</td>
                      <td>{{payment.payment.accountNumber}}</td>
                      <td>{{payment.payment.gatewayPaymentId}}</td>
                      <td>{{payment.payment.amount | currency}}</td>
                      <td>{{payment.payment.date | isoDateEnum}}</td>
                      <td>{{payment.application.status | selectEnum:$_statusPaymentLoan}}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('LOAN','READ_PAYMENTS_TABLE');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-file-invoice"></i>
              <span class="ml-2">Tabla de pagos</span>
            </ng-template>
            <div class="grid">
              <div class="col-12">
                <div class="main-title">
                  Tabla de pagos
                </div>
              </div>
              <div class="col-12">
                <p-table #dtOnlyPayments [value]="cleanPayments" [tableStyle]="{ 'min-width': '50rem' }">
                  <ng-template pTemplate="caption">
                    <div class="flex flex-row justify-content-between">
                      <ng-container *ngIf="authService.userHasAuthorization('LOAN','EXPORT_APPLICATIONS_TABLE');">
                        <p-splitButton label="Acciones" [model]="itemsPayments" icon="fa-solid fa-gears" styleClass="p-button-outlined p-button-info mr-2 mb-2"></p-splitButton>
                      </ng-container>
                      <div>
            <span class="p-input-icon-left mr-1">
              <i class="pi pi-search"></i>
              <input pInputText type="text" (input)="dtPayments.filterGlobal(getValue($event), 'contains')" placeholder="Buscar..." />
            </span>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="header">
                    <tr>
                      <th  style="min-width: 10rem">Número de pago</th>
                      <th  style="min-width: 10rem">Fecha de pago</th>
                      <th  style="min-width: 10rem">Monto del pago</th>
                      <th  style="min-width: 10rem">Clave de rastreo</th>
                      <th  style="min-width: 10rem">CLABE receptora</th>
                      <th  style="min-width: 10rem">Fecha de creación</th>
                      <th  style="min-width: 10rem">Estatus</th>
                      <th  style="min-width: 10rem">Acciones</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-payment let-rowIndex="rowIndex">
                    <tr>
                      <td>{{payment.index}}</td>
                      <td>{{payment.date | isoDateEnum}}</td>
                      <td>{{payment.amount | currency}}</td>
                      <td>{{payment.gatewayPaymentId}}</td>
                      <td>{{payment.accountNumber}}</td>
                      <td>{{payment.creationDate | isoDateEnum}}</td>
                      <td>{{payment.status | selectEnum:$_statusPaymentLoan}}</td>
                      <td>
                        <ng-container *ngIf="authService.userHasAuthorization('LOAN','CANCEL_PAYMENT') && payment.status === 'APPLIED' && isTheLastAppliedPayment(payment._id)">
                          <button pButton pRipple type="button" label="Cancelar" icon="fa-solid fa-times" class="p-button-rounded p-button-danger p-button-sm" (click)="confirmCancellation($event, payment._id)"></button>
                        </ng-container>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
        </ng-container>
        <ng-container *ngIf="authService.userHasAuthorization('WRITEOFF','READ_CONDONATION_TABLE');">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="fa-solid fa-file-invoice"></i>
              <span class="ml-2">Tabla de condonaciones</span>
            </ng-template>
            <div class="grid">
              <div class="col-12">
                <div class="main-title">
                  Tabla de condonaciones
                </div>
              </div>
              <div class="col-12">
                <p-table #dtWriteOff
                         [scrollable]="true"
                         scrollHeight="600px"
                         [value]="writeOffs"
                         [tableStyle]="{ 'min-width': '50rem' }"
                         dataKey="id"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th  style="min-width: 10rem">Número de letra</th>
                      <th  style="width: 10rem">Estatus</th>
                      <th  style="width: 10rem">Detalles de Condonación</th>
                      <th  style="min-width: 10rem">Razon</th>
                      <th  style="min-width: 10rem">Fecha de la aplicación</th>
                      <th  style="min-width: 10rem">Asesor</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-writeOff let-expanded="expanded">
                    <tr>
                      <td>{{writeOff.index}}</td>
                      <td>{{writeOff.status | selectEnum: $_statusWriteOff}}</td>
                      <td>
                        <button type="button" pButton pRipple [pRowToggler]="writeOff" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                      </td>
                      <td>{{writeOff.reason}}</td>
                      <td>{{writeOff.date | isoDateEnum}}</td>
                      <td>{{writeOff.adminFullName}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="rowexpansion" let-item>
                    <tr>
                      <td colspan="7">
                        <div class="p-3">
                          <p-table [value]="item.debtRelief" dataKey="concept">
                            <ng-template pTemplate="header">
                              <tr>
                                <th pSortableColumn="concept">Tipo de monto <p-sortIcon field="concept"></p-sortIcon></th>
                                <th pSortableColumn="originAmount">Monto Origina <p-sortIcon field="originAmount"></p-sortIcon></th>
                                <th pSortableColumn="write">Monto despues de condonar <p-sortIcon field="write"></p-sortIcon></th>
                                <th pSortableColumn="debtRelief">Monto condonado <p-sortIcon field="debtRelief"></p-sortIcon></th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-debt>
                              <tr>
                                <td>{{ debt.concept | selectEnum:$_writeOffConcept }}</td>
                                <td>{{ debt.originAmount | currency}}</td>
                                <td>{{ debt.write | currency}}</td>
                                <td>{{ debt.debtRelief | currency}}</td>
                              </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="7">Aún no se ha realizado ninguna condonación.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
          </p-tabPanel>
        </ng-container>
      </p-tabView>
    </div>
  </div>
</ng-container>

<!------------------------CREATE WRITE-OFF ---------------------------->
<p-dialog [(visible)]="createEntity" [style]="{ width: '75vw' }" [header]="'Nueva Condonación'" [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <app-create-write-off
      *ngIf="!hasPendingWriteOff"
      [entity]="entity" [writeOffData]="writeOffData"
      [isPayrollAction]="isWriteOffAndPayroll"
      [loan]="data"
      (confirmation)="writeOffResult($event)"
    ></app-create-write-off>
  </ng-template>
  <p-card *ngIf="hasPendingWriteOff" header="Condonaciones por procesar">
    <p class="m-0">
      El crédito cuenta con condonaciones pendientes de procesamiento.
    </p>
    <p class="mt-2">
      Por favor, finalice estas condonaciones para poder crear una nueva.
    </p>
  </p-card>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    <button *ngIf="(
      !authService.userHasAuthorization('WRITEOFF','APPROVE') && !authService.userHasAuthorization('WRITEOFF','WRITEOFF_N_PAYROLL')
      ) && !hasPendingWriteOff"
            pButton pRipple label="Solicitar Condonación" icon="pi pi-check" class="p-button-text" (click)="saveWriteOff(false)" [disabled]="writeOffDisabled"></button>
    <button *ngIf="(
      authService.userHasAuthorization('WRITEOFF','APPROVE') || authService.userHasAuthorization('WRITEOFF','WRITEOFF_N_PAYROLL')
      ) && !hasPendingWriteOff"
      pButton pRipple label="Condonar" icon="pi pi-check" class="p-button-text" (click)="saveWriteOff(true)" [disabled]="writeOffDisabled"></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<p-toast></p-toast>
